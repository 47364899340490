/* eslint-disable @typescript-eslint/ban-ts-comment */
import { fuelOptions } from "../constants";
import { calculateElectricitySellPrice } from "../../util";
const ELECTRICITY_VALUE_KEYS = {
    electricity_discount: 'discount',
    discount_electricity_required: 'discountRequired',
    electricity_sell_price_reduction: 'sellPriceReduction',
    electricity_minimum_sell_price: 'minimumSellPrice',
};
const INFLATION_VALUE_KEYS = {
    inflation_years: 'years',
    inflation_rate: 'rate',
};
const POWER_CORE_SAVINGS_KEYS = {
    emaldo_power_core_savings_dk1: 'dk1',
    emaldo_power_core_savings_dk2: 'dk2',
};
const SUBSIDY_KEYS = {
    heat_pump_subsidy: 'heatPump',
    solar_subsidy: 'solar',
};
const TAX_DEDUCTION_KEYS = {
    craftsmanship_tax_deduction: 'craftsman',
};
const BEAM_PAYOUT_KEYS = {
    beam_payout_dk2_fcr_d: 'dk2',
};
export const FUEL_KEYS = [
    'wood_1',
    'wood_2',
    'pallet',
    'electricity',
    'oil',
    'gas',
    'wood',
    'straw',
    'inflation',
    'power_core_savings',
    'subsidy',
    'tax_deduction',
    'beam_payout',
];
const valueKeys = ['calorific', 'price', 'emission'];
export const mapConstantsToEnergyOptions = (energyConstants, electricityPrice, heatings = [], applyInflation = false) => {
    const energyOptions = energyConstants.reduce((options, constant) => {
        const { code, valueNumber, denominator } = constant;
        const fuelKey = FUEL_KEYS.find(key => code.includes(key));
        if (!fuelKey) {
            return options;
        }
        if (['wood_1', 'wood_2'].includes(fuelKey)) {
            const volumeUnit = denominator;
            if (!volumeUnit) {
                return options;
            }
            const valueKey = valueKeys.find(key => code.includes(key));
            if (!valueKey) {
                return options;
            }
            return {
                ...options,
                [fuelKey]: {
                    ...options[fuelKey],
                    [volumeUnit]: {
                        // @ts-ignore
                        ...options[fuelKey]?.[volumeUnit],
                        [valueKey]: valueNumber,
                        ...(!code.includes('_emission') && denominator ? { denominator } : {}),
                    },
                },
            };
        }
        if (fuelKey === 'power_core_savings') {
            return {
                ...options,
                [fuelKey]: { ...options[fuelKey], [POWER_CORE_SAVINGS_KEYS[code]]: valueNumber },
            };
        }
        if (fuelKey === 'subsidy') {
            return {
                ...options,
                [fuelKey]: { ...options[fuelKey], [SUBSIDY_KEYS[code]]: valueNumber },
            };
        }
        if (fuelKey === 'tax_deduction') {
            return {
                ...options,
                [fuelKey]: { ...options[fuelKey], [TAX_DEDUCTION_KEYS[code]]: valueNumber },
            };
        }
        if (fuelKey === 'beam_payout') {
            return {
                ...options,
                [fuelKey]: { ...options[fuelKey], [BEAM_PAYOUT_KEYS[code]]: valueNumber },
            };
        }
        const electricityKey = ELECTRICITY_VALUE_KEYS[code];
        if (electricityKey) {
            return {
                ...options,
                electricity: {
                    ...options.electricity,
                    [electricityKey]: valueNumber,
                },
            };
        }
        const inflationKey = INFLATION_VALUE_KEYS[code];
        if (inflationKey) {
            const value = applyInflation ? valueNumber : 0;
            return {
                ...options,
                inflation: {
                    ...options.inflation,
                    [inflationKey]: inflationKey === 'rate' ? value / 100 : value,
                },
            };
        }
        const valueKey = valueKeys.find(key => code.includes(key));
        if (!valueKey) {
            return options;
        }
        // Electricity fuelType should apply to all heating types using electricity
        // even though they don't have individual constant rows
        if (fuelKey === 'electricity') {
            const fuelValue = {
                ...options[fuelKey],
                [valueKey]: valueNumber,
                ...(!code.includes('_emission') && denominator ? { denominator } : {}),
            };
            return { ...options, [fuelKey]: fuelValue };
        }
        return {
            ...options,
            [fuelKey]: {
                ...options[fuelKey],
                [valueKey]: valueNumber,
                ...(!code.includes('_emission') && denominator ? { denominator } : {}),
            },
        };
    }, fuelOptions);
    return attachHeatingPricesToEnergyOptions(attachCustomerElectricityPriceToEnergyOptions(energyOptions, electricityPrice), heatings);
};
export const findConstantForHeating = (heating, energyOptions = fuelOptions) => {
    const fuelSubtype = heating?.fuelSubtype;
    const volumeUnit = heating?.volumeUnit;
    const fuelType = heating?.fuelType;
    const woodEnergyOption = energyOptions[fuelSubtype];
    if (woodEnergyOption && woodEnergyOption[volumeUnit]) {
        return woodEnergyOption[volumeUnit];
    }
    if (fuelType?.toLowerCase()?.includes('heatpump')) {
        return energyOptions['electricity'];
    }
    return energyOptions[fuelType];
};
export const attachCustomerElectricityPriceToEnergyOptions = (energyOptions, customerPrice) => {
    const { electricity } = energyOptions;
    const { price: defaultPrice, sellPriceReduction, minimumSellPrice } = electricity ?? {};
    const price = customerPrice ?? defaultPrice;
    const sellPrice = Math.max(calculateElectricitySellPrice(price ?? 0, sellPriceReduction ?? 0), minimumSellPrice ?? 0);
    return {
        ...energyOptions,
        electricity: { ...electricity, price, sellPrice },
    };
};
export const attachHeatingPricesToEnergyOptions = (energyOptions, heatings = []) => {
    if (heatings.length === 0) {
        return energyOptions;
    }
    const newOptions = JSON.parse(JSON.stringify(energyOptions));
    for (const heating of heatings) {
        const { fuelType, fuelSubtype, price, volumeUnit } = heating;
        const fuelKey = fuelType;
        const fuelSubtypeKey = fuelSubtype;
        if (!price || price < 0 || !fuelKey || fuelKey === 'electricity') {
            continue;
        }
        if (!fuelSubtype) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newOptions[fuelKey] = { ...energyOptions[fuelKey], price };
        }
        const volumeUnitKey = volumeUnit;
        if (!volumeUnitKey) {
            continue;
        }
        newOptions[fuelSubtypeKey] = {
            ...energyOptions[fuelSubtypeKey],
            [volumeUnitKey]: {
                ...(energyOptions[fuelSubtypeKey]?.[volumeUnitKey] ?? {}),
                price,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        };
    }
    return newOptions;
};
